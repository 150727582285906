// Redefine variables

$primary: #007bff; // Change primary color
$body-bg: #f8f9fa; // Change body background color

// Import Bootstrap's SCSS
@import '~bootstrap/scss/bootstrap';

// Specific styles for ProductCard
.product-card {
  height: 100%; // Ensure all product cards take full height of their container
  display: flex;
  flex-direction: column; // Make the card a flex container
}

.product-card .card-body {
  display: flex;
  flex-direction: column; // Make the card body a flex container
  flex-grow: 1; // Allow card body to grow and fill available space
}

.product-card .card-text {
  flex-grow: 1; // Allow text content to grow and fill space
}

.product-card .btn {
  margin-top: auto; // Ensure button is pushed to the bottom of the card
}